.cd-dashboard-card-bottomline {
  display: flex;
  margin-bottom: -10px;

  .__item {
    flex: 0 0 auto;
    padding: 0 11px 0 0;

    + .__item {
      border-width: 0 0 0 1px;
      border-style: solid;
      padding-left: 11px;
    }
  }

  .__text {
    border-width: 0 0 1px 0;
    border-style: dotted;
  }
}
