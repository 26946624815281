@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.linkColor {
  color: inherit !important;
}
pre {
  white-space: pre-wrap;
  background-color: #f8f9f9;
  border: 1px solid #c2c8cc;
  line-height: 19px;
  padding: 6px 10px;
  border-radius: 3px;
  margin: 15px 0;
  font-family: "Courier New", Courier, monospace !important;
}
.ql-toolbar {
  border-radius: 4px 4px 0 0;
}
.ql-container {
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  resize: vertical;
  min-height: 220px;
}
.quill {
  background-color: rgb(255, 255, 255);
}
.ql-snow .ql-editor pre.ql-syntax {
  white-space: pre-wrap;
  background-color: #f8f9f9 !important;
  border: 1px solid #c2c8cc !important;
  color: rgba(0, 0, 0, 0.87) !important;
  line-height: 19px;
  padding: 6px 10px;
  border-radius: 3px;
  margin: 15px 0;
  font-family: "Courier New", Courier, monospace !important;
}

.ql-fullscreen {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* line-height: 1; */
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
}
.ql-fullscreen:after {
  content: "";
}
blockquote {
  padding-left: 10px;
  border-left: 2px solid #c2c8cc;
  margin: 0 0 0 0;
}
