@font-face {
  font-family: "matter-bold";
  src:
    local("matter-bold"),
    url("./fonts/matter-bold.ttf") format("truetype");
}

@font-face {
  font-family: "matter-regular";
  src:
    local("matter-regular"),
    url("./fonts/matter-regular.ttf") format("truetype");
}
